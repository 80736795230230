//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import GdprCookieModalCheckbox from './GdprCookieModalCheckbox'

export default {
  name: 'GdprCookieModal',
  components: { GdprCookieModalCheckbox },
  data() {
    return {
      state: 0,
      texts: {
        mainPopup: {
          readMore: 'More',
          acceptAll: 'Accept all',
          settings: 'Settings',
          saveSettings: 'Save',
          declineSettings: 'Refuse',
          defaultMainText:
            'To make the site more user-friendly, we use cookies. They help us collect and analyze statistics, including for marketing purposes. We store cookies during your visit only if you allow us to do so. #cookiePolicyLink# и #privacyPolicyLink#.',
          cookiePolicyLink:
            '<a :href="/privacy#cookies" target="_blank" rel="noreferrer">#cookiePolicyLinkText#</a>',
          cookiePolicyLinkText: 'Cookie Policy',
          privacyPolicyLink:
            '<a :href="/privacy#cookies" target="_blank" rel="noreferrer">#privacyPolicyLinkText#</a>',
          privacyPolicyLinkText: 'Privacy Policy',
        },
        required: {
          title: 'Strictly Necessary Cookies',
          readMore: 'More',
          shortDesc:
            'These cookies are necessary for the proper functioning of our website.',
          longDesc:
            'They guarantee its safety and the correct display of content. ' +
            'Usually they are set only in response to your actions, which ' +
            'are tantamount to requesting services such as setting your preferences' +
            'privacy, logging in or filling out forms.',
          longDescIsActive: false,
        },
        analytics: {
          title: 'Analytical cookies',
          readMore: 'More',
          shortDesc:
            'These cookies allow us to count visits and traffic sources,' +
            'so we can measure and improve the performance of our site.',
          longDesc:
            'These cookies allow us to count visits and traffic sources,' +
            'so we can measure and improve the performance of our site.',
          longDescIsActive: false,
        },
        marketing: {
          title: 'Marketing cookies',
          readMore: 'More',
          shortDesc:
            'These cookies may be set through our website by our advertising partners.',
          longDesc:
            'These cookies may be set through our website by our advertising partners.',
          longDescIsActive: false,
        },
      },
      decisions: {
        required: this.decisionsRequired,
        analytics: this.decisionsAnalytics,
        marketing: this.decisionsMarketing,
      },
    }
  },
  computed: {
    ...mapGetters({
      decisionsRequired: 'gdpr/decisionsRequired',
      decisionsAnalytics: 'gdpr/decisionsAnalytics',
      decisionsMarketing: 'gdpr/decisionsMarketing',
      gdprSaved: 'gdpr/gdprSaved',
    }),
  },
  mounted() {
    setTimeout(this.openSmall, 1000)
    if (window.innerWidth <= 960) {
      const maxTextHeight = window.innerHeight - 133

      if (this.$refs['scroll-text-1'] !== undefined) {
        this.$refs['scroll-text-1'].style.maxHeight = maxTextHeight + 'px'
      }
      if (this.$refs['scroll-text-2'] !== undefined) {
        this.$refs['scroll-text-2'].style.maxHeight = maxTextHeight + 'px'
      }
      if (this.$refs['scroll-modal-1'] !== undefined) {
        this.$refs['scroll-modal-1'].style.maxHeight = window.innerHeight + 'px'
      }
      if (this.$refs['scroll-modal-2'] !== undefined) {
        this.$refs['scroll-modal-2'].style.maxHeight = window.innerHeight + 'px'
      }
    }
  },
  methods: {
    getText(translationAlias, defaultValue = 'default') {
      const text = this.$getTextMainSetting(translationAlias)

      if (text === null || text === undefined || text.length <= 0) {
        return defaultValue
      }

      return text
    },
    getDetailedMainPopupText() {
      let mainText = this.getText(
        'gdprMainPopupDetailedDescription',
        this.texts.mainPopup.defaultMainText
      )
      const cookiePolicyLinkText = this.getText(
        'gdprMainPopupCookieLinkText',
        this.texts.mainPopup.cookiePolicyLinkText
      )
      const privacyPolicyLinkText = this.getText(
        'gdprMainPopupPrivacyLinkText',
        this.texts.mainPopup.privacyPolicyLinkText
      )

      const cookiePolicyLink = this.texts.mainPopup.cookiePolicyLink.replaceAll(
        '#cookiePolicyLinkText#',
        cookiePolicyLinkText
      )
      const privacyPolicyLink =
        this.texts.mainPopup.privacyPolicyLink.replaceAll(
          '#privacyPolicyLinkText#',
          privacyPolicyLinkText
        )
      mainText = mainText.replaceAll('#cookiePolicyLink#', cookiePolicyLink)
      mainText = mainText.replaceAll('#privacyPolicyLink#', privacyPolicyLink)

      return mainText
    },
    setGdprDecision(context) {
      this.decisions[context.name] = context.value
    },
    openSmall() {
      this.state = 'small'
      if (window.innerWidth <= 640) {
        document
          .querySelector('body')
          .classList.remove('cookie-modal-is-active')
      }
    },
    openDesc() {
      this.state = 'desc'
      if (window.innerWidth <= 640) {
        document.querySelector('body').classList.add('cookie-modal-is-active')
      }
    },
    openSettings() {
      this.state = 'settings'
      if (window.innerWidth <= 640) {
        document.querySelector('body').classList.add('cookie-modal-is-active')
      }
    },
    saveSettings() {
      this.$store.dispatch('gdpr/setDecisions', { decisions: this.decisions })
      this.openSmall()
    },
    declineSettings() {
      this.openSmall()
    },
    acceptCookies() {
      const decisions = {
        required: true,
        analytics: true,
        marketing: true,
      }
      this.$store.dispatch('gdpr/setDecisions', { decisions })
      this.state = 0
    },
  },
}

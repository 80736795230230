//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VueModal',
  props: {
    modalName: {
      type: String,
      required: true,
    },
    modalMaxWidth: {
      type: Number || String,
      required: false,
      default: 1300,
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.$emit('on-before-open', event)
    },
    handleClose(name) {
      this.$modal.hide(name)
    },
  },
}

//
//
//
//

export default {
  name: 'Preloader',
  data() {
    return {
      isPreloaderActive: true,
    }
  },
  mounted() {
    setTimeout(() => {
      this.isPreloaderActive = false
    }, 500)
  },
}

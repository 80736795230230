//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CookieModalCheckbox',
  props: {
    checkboxName: {
      type: String,
      required: true,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      required: true,
      default: false,
    },
    isChecked: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      checked: this.isChecked,
    }
  },
  watch: {
    isChecked(newValue, oldValue) {
      this.checked = newValue
    },
  },
  methods: {
    change(event) {
      this.$emit('change', { name: this.checkboxName, value: this.checked })
    },
  },
}

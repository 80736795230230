//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import branches from '@/modules/ekbModal/consts/branches'
import { getCookie, setCookie } from '@/utils/cookie'
import VueModal from '~/components/common/VueModal'

export default {
  name: 'EkbModal',
  components: {
    VueModal,
  },
  data() {
    return {
      branches,
    }
  },
  mounted() {
    const districtSelected = getCookie('ekb-district')
    if (
      branches.includes(this.$getTextSetting('branch_id')) &&
      !districtSelected
    ) {
      this.$modal.show('modal-ekb')
    }
  },
  methods: {
    onClick(event) {
      setCookie('ekb-district', 'district_selected', {
        secure: true,
        'max-age': 3600,
        domain: '.algoritmika.org',
      })
      if (process.client) {
        if (
          event.target.href ===
          window.location.protocol + '//' + window.location.host + '/'
        ) {
          this.$modal.hide('modal-ekb')
        } else {
          window.location.href = event.target.href
        }
      }
    },
    onBeforeOpen(event) {
      this.mathCourseParams = event.params
    },
    onBeforeOpenSignupCourses(event) {
      this.signupCoursesParams = event.params
    },
    onMathFormSubmitted(index) {
      this.$modal.hide('modal-course-0' + index)
    },
  },
}
